import React, { useState } from 'react'

import SidePanel from '../SidePanel/SidePanel'
import AuthNavBar from '../Dashboard/AuthNavBar'

function Analytics() {
	const [isPanelOpen, setIsPanelOpen] = useState(false)

	const togglePanel = () => {
		setIsPanelOpen(!isPanelOpen)
	}

	return (
		<div>
			<AuthNavBar togglePanel={togglePanel} />
			<SidePanel isOpen={isPanelOpen} onClose={() => setIsPanelOpen(false)} />
			Analytics Page
		</div>
	)
}

export default Analytics
