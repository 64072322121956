import React from 'react'
import { Link } from 'react-router-dom'

import Logo from '../../media/logo.svg'

const chromeWebstoreUrl =
	'https://chromewebstore.google.com/detail/loadlineai/cpcnkckpbpefgkcilhclfdefccflgaif'

function NavBar({ isNotLandingPage }) {
	return (
		<div>
			<nav className="bg-[#001b2e] py-4">
				<div className="container mx-auto flex justify-between items-center">
					<div className="flex items-center">
						<Link to="/" className="flex items-center">
							<img src={Logo} alt="LoadLine Logo" className="h-8 w-auto mr-1" />
							<span className="text-2xl font-bold text-white">LoadLine</span>
							<span className="ml-1 font-medium text-2xl text-white">AI</span>
						</Link>
					</div>

					{!isNotLandingPage && (
						<div>
							<Link
								to="/login"
								className="buttonGlow mr-4 text-white font-bold"
							>
								Login
							</Link>

							<a
								href={chromeWebstoreUrl}
								target="_blank"
								rel="noopener noreferrer"
								className={`gradient-bg text-white px-3 py-2 rounded-lg text-md font-bold hover:opacity-90 transition duration-300 inline-block shadow-lg`}
							>
								Get started now
							</a>
						</div>
					)}
				</div>
			</nav>
		</div>
	)
}

export default NavBar
