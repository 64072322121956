const EmailAccessModal = ({ isOpen, onClose, onAuthorize }) => {
	if (!isOpen) return null

	return (
		<div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
			<div className="bg-white rounded-lg p-8 max-w-md w-full">
				<h2 className="text-2xl font-bold mb-4">Grant Email Access</h2>
				<p className="mb-6">
					To enable email sending functionality, we need your permission to
					access your email account.
				</p>
				<div className="flex flex-col space-y-4">
					<button
						onClick={() => onAuthorize('gmail')}
						className="bg-red-500 hover:bg-red-600 text-white font-bold py-2 px-4 rounded transition duration-150 ease-in-out"
					>
						Grant Gmail Access
					</button>

					<button
						onClick={() => onAuthorize('custom')}
						className="bg-green-500 hover:bg-green-600 text-white font-bold py-2 px-4 rounded transition duration-150 ease-in-out"
					>
						Set Up Custom Email
					</button>
					<button
						onClick={onClose}
						className="bg-gray-300 hover:bg-gray-400 text-gray-800 font-bold py-2 px-4 rounded transition duration-150 ease-in-out"
					>
						Skip for Now
					</button>
				</div>
			</div>
		</div>
	)
}

export default EmailAccessModal
