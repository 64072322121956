import React from 'react'
import { Routes, Route, useLocation, Navigate } from 'react-router-dom'

import LandingPage from './components/LandingPage/LandingPageMain'
import Policy from './components/Policy/Policy'
import Login from './components/Auth/Login'
import Register from './components/Auth/Register'
import Dashboard from './components/Dashboard/DashboardMain'
import ExtensionAuth from './components/ExtensionAuth/ExtensionAuth'
import Settings from './components/Settings/Settings'
import Analytics from './components/Analytics/Analytics'
import SuccessMessageHandler from './components/EmailAccessModal/EmailSuccessMessageHandler'

function AppRouter() {
	const location = useLocation()

	const user = localStorage.getItem('jwtToken')

	const isFromExtension =
		new URLSearchParams(location.search).get('from') === 'extension'

	if (user) {
		if (isFromExtension) {
			return (
				<Routes>
					<Route path="/extension" element={<ExtensionAuth token={user} />} />
					<Route path="*" element={<Navigate to="/extension" replace />} />
				</Routes>
			)
		}

		return (
			<>
				<SuccessMessageHandler />
				<Routes>
					<Route path="/broker-info" element={<Dashboard />} />
					<Route path="/extension" element={<ExtensionAuth token={user} />} />
					<Route path="/policy" element={<Policy />} />
					<Route path="/settings" element={<Settings />}>
						<Route
							index
							element={<Navigate to="/settings/general" replace />}
						/>
						<Route path=":tab" element={<Settings />} />
					</Route>
					<Route path="/analytics" element={<Analytics />} />

					<Route path="*" element={<Navigate to="/broker-info" replace />} />
				</Routes>
			</>
		)
	}

	return (
		<Routes>
			<Route path="/" element={<LandingPage />} />
			<Route path="/login" element={<Login />} />
			<Route path="/register" element={<Register />} />
			<Route path="/policy" element={<Policy />} />
			<Route path="/extension" element={<ExtensionAuth />} />
			<Route path="*" element={<Navigate to="/" replace />} />
		</Routes>
	)
}

export default AppRouter
