import React, { useEffect, useState } from 'react'
import { jwtDecode } from 'jwt-decode'
import { useNavigate } from 'react-router-dom'

function ExtensionAuth({ token }) {
	const navigate = useNavigate()
	const [email, setEmail] = useState('')
	const [confirmed, setConfirmed] = useState(false)

	useEffect(() => {
		if (token) {
			try {
				const decodedToken = jwtDecode(token)
				const currentTime = Date.now() / 1000

				if (decodedToken.exp && decodedToken.exp < currentTime) {
					console.log('Token is expired')
					handleLogout()
				} else {
					setEmail(decodedToken.email)
				}
			} catch (error) {
				console.error('Error decoding token:', error)
				handleLogout()
			}
		}
	}, [token])

	const handleConfirmation = () => {
		setConfirmed(true)
		window.opener.postMessage({ type: 'AUTH_SUCCESS', payload: { token } }, '*')
	}

	const handleLogout = () => {
		localStorage.removeItem('jwtToken')
		navigate('/login?from=extension')
	}

	return (
		<div className="flex flex-col items-center justify-center min-h-screen bg-gray-100">
			<div className="bg-white p-8 rounded shadow-md w-full max-w-md">
				<h1 className="text-2xl font-semibold mb-6 text-center">
					Welcome to the Extension Page
				</h1>
				<p className="text-center mb-6">
					This page is accessible only via the extension popup.
				</p>
				{email && !confirmed && (
					<div className="mb-6">
						<p className="text-center mb-4">
							Do you want to sign in with the following email account?
						</p>
						<p className="text-center text-blue-500 mb-4">{email}</p>
						<div className="flex justify-center">
							<button
								className="bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-600"
								onClick={handleConfirmation}
							>
								Confirm
							</button>
						</div>
					</div>
				)}
				{confirmed && (
					<p className="text-center text-green-500 mb-6">
						You have successfully signed in.
					</p>
				)}
				<div className="flex justify-center">
					<button
						className="bg-red-500 text-white py-2 px-4 rounded hover:bg-red-600"
						onClick={handleLogout}
					>
						Logout
					</button>
				</div>
			</div>
		</div>
	)
}

export default ExtensionAuth
