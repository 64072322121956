import React from 'react'

function BrokerPanel({ isOpen, onClose, broker }) {
	if (!isOpen) return null

	return (
		<>
			<div
				className="fixed inset-0 bg-black bg-opacity-50 z-40"
				onClick={onClose}
			></div>
			<div
				className={`fixed top-0 right-0 w-4/6 h-full bg-[#002b44] text-white shadow-lg z-50 transition-all duration-500 ease-in-out transform ${
					isOpen ? 'translate-x-0' : 'translate-x-full'
				}`}
			>
				<div className="h-16 border-b border-gray-600 flex items-center px-6">
					<button onClick={onClose} className="text-white hover:text-gray-300">
						<svg
							className="w-6 h-6"
							fill="none"
							stroke="currentColor"
							viewBox="0 0 24 24"
							xmlns="http://www.w3.org/2000/svg"
						>
							<path
								strokeLinecap="round"
								strokeLinejoin="round"
								strokeWidth="2"
								d="M6 18L18 6M6 6l12 12"
							></path>
						</svg>
					</button>
					<h2 className="text-lg font-bold ml-3">{broker.companyName}</h2>
				</div>
				<div className="p-6">
					{broker ? (
						<div>
							<p className="mb-2">MC: {broker.mcNumber}</p>
							<p className="mb-2">DOT: {broker.dotNumber}</p>
							{broker.domain && (
								<p className="mb-2">Domain: @{broker.domain.toUpperCase()}</p>
							)}
							{broker.address && (
								<p className="mb-2">
									{broker.address.city}, {broker.address.state}{' '}
									{broker.address.zipCode}
								</p>
							)}
						</div>
					) : (
						<p>No broker selected</p>
					)}
				</div>
			</div>
		</>
	)
}

export default BrokerPanel
