import React from 'react'

function GeneralSettings() {
	return (
		<>
			<h2 className="text-2xl font-bold mb-4 text-white">General Settings</h2>
		</>
	)
}

export default GeneralSettings
