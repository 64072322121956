import React, { useState, useEffect } from 'react'
import axios from 'axios'

import ConnectEmailAccountModal from './ConnectEmailAccountModal'

function EmailSettings() {
	const [templates, setTemplates] = useState([])
	const [accounts, setAccounts] = useState([])
	const [loading, setLoading] = useState(true)
	const [error, setError] = useState(null)
	const [isConnectModalOpen, setIsConnectModalOpen] = useState(false)
	const [isMaxAccountsModalOpen, setIsMaxAccountsModalOpen] = useState(false)

	const fetchEmailData = async () => {
		try {
			const response = await axios.get('/api/email/templates', {
				headers: {
					Authorization: `Bearer ${localStorage.getItem('jwtToken')}`,
				},
			})
			setTemplates(response.data.templates)
			setAccounts(response.data.accounts)
			setLoading(false)
		} catch (err) {
			setError('Error fetching email data')
			setLoading(false)
		}
	}

	useEffect(() => {
		fetchEmailData()
	}, [])

	const handleEmailAuthorization = async provider => {
		try {
			if (accounts.length >= 3) {
				setIsMaxAccountsModalOpen(true)
				return
			}

			const response = await axios.post(
				'/api/user/request-email-access',
				{ provider },
				{
					headers: {
						Authorization: `Bearer ${localStorage.getItem('jwtToken')}`,
					},
				}
			)

			if (response.data.authUrl) {
				window.location.href = response.data.authUrl
			} else {
				console.log('Custom email setup or other provider selected')
			}
		} catch (error) {
			console.error('Error requesting email access:', error)
			if (error.response && error.response.status === 400) {
				setError(error.response.data.message)
			} else {
				setError('An error occurred while requesting email access')
			}
		}
	}

	const handleDeleteAccount = async accountId => {
		try {
			await axios.delete(`/api/user/email-account/${accountId}`, {
				headers: {
					Authorization: `Bearer ${localStorage.getItem('jwtToken')}`,
				},
			})
			fetchEmailData() // Refresh the accounts list
		} catch (error) {
			console.error('Error deleting email account:', error)
			setError('An error occurred while deleting the email account')
		}
	}

	const handleModalOpen = () => {
		if (accounts.length >= 3) {
			setIsMaxAccountsModalOpen(true)
			return
		}

		setIsConnectModalOpen(true)
	}

	if (loading) return <div className="text-white">Loading...</div>
	if (error) return <div className="text-red-500">{error}</div>

	return (
		<>
			<div className="text-white">
				<h2 className="text-2xl font-bold mb-6">Email Settings</h2>

				<section className="mb-8">
					<h3 className="text-xl font-semibold mb-4">Email Accounts</h3>
					{accounts.length > 0 ? (
						<ul className="space-y-2">
							{accounts.map(account => (
								<li
									key={account._id}
									className="bg-[#003a5c] p-3 rounded flex justify-between items-center"
								>
									<div>
										<span className="font-medium">{account.email}</span>
										<span
											className={`ml-2 px-2 py-1 rounded text-sm ${
												account.canSendEmail ? 'bg-green-600' : 'bg-red-600'
											}`}
										>
											{account.canSendEmail
												? 'Can send emails'
												: 'Cannot send emails'}
										</span>
									</div>
									<button
										onClick={() => handleDeleteAccount(account._id)}
										className="bg-red-600 hover:bg-red-700 px-3 py-1 rounded"
									>
										Delete
									</button>
								</li>
							))}
						</ul>
					) : (
						<p className="text-gray-400">No email accounts connected.</p>
					)}

					<button
						className="mt-4 bg-blue-600 hover:bg-blue-700 px-4 py-2 rounded"
						onClick={handleModalOpen}
					>
						Connect New Account
					</button>
				</section>

				<section>
					<h3 className="text-xl font-semibold mb-4">Email Templates</h3>
					{templates.length > 0 ? (
						<ul className="space-y-4">
							{templates.map(template => (
								<li key={template._id} className="bg-[#003a5c] p-4 rounded">
									<h4 className="text-lg font-medium mb-2">{template.name}</h4>
									<p className="text-gray-300 mb-2">
										<strong>Subject:</strong> {template.subject}
									</p>
									<div className="text-gray-300 mb-2">
										<strong>Body:</strong>
										<div
											dangerouslySetInnerHTML={{ __html: template.body }}
											className="mt-1 bg-[#002b44] p-2 rounded"
										/>
									</div>
									<div className="flex space-x-2 mt-4">
										<button className="bg-yellow-600 hover:bg-yellow-700 px-3 py-1 rounded">
											Edit
										</button>
										<button className="bg-red-600 hover:bg-red-700 px-3 py-1 rounded">
											Delete
										</button>
									</div>
								</li>
							))}
						</ul>
					) : (
						<p className="text-gray-400">No email templates available.</p>
					)}
					<button className="mt-6 bg-green-600 hover:bg-green-700 px-4 py-2 rounded">
						Create New Template
					</button>
				</section>
			</div>

			<ConnectEmailAccountModal
				isOpen={isConnectModalOpen}
				onClose={() => setIsConnectModalOpen(false)}
				onAuthorize={handleEmailAuthorization}
			/>

			{/* Max Accounts Modal */}
			{isMaxAccountsModalOpen && (
				<div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
					<div className="bg-[#002b44] rounded-lg p-8 max-w-md w-full text-white">
						<h2 className="text-2xl font-bold mb-4">
							Maximum Accounts Reached
						</h2>
						<p className="mb-6 text-gray-300">
							You have reached the maximum limit of 3 email accounts. Please
							delete an existing account before adding a new one.
						</p>
						<button
							onClick={() => setIsMaxAccountsModalOpen(false)}
							className="bg-blue-600 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded transition duration-150 ease-in-out"
						>
							Close
						</button>
					</div>
				</div>
			)}
		</>
	)
}

export default EmailSettings
