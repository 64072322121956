import React, { useState, useEffect } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import googleIcon from '../../media/google.svg'

import NavBar from '../LandingPage/NavBar'

function Login() {
	const navigate = useNavigate()
	const location = useLocation()

	const [email, setEmail] = useState('')
	const [password, setPassword] = useState('')
	const [errorMessage, setErrorMessage] = useState('')

	const devURI = 'http://localhost:5004'
	const productionURI = ''
	const authUrl = productionURI
	const googleAuthUrl = `${authUrl}/auth/google`

	const handleSubmit = async e => {
		e.preventDefault()
		try {
			const response = await fetch('/api/auth/login', {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
				},
				body: JSON.stringify({ email, password }),
			})

			if (response.ok) {
				const data = await response.json()
				console.log('Login successful:', data)
				localStorage.setItem('jwtToken', data.token)

				const searchParams = new URLSearchParams(location.search)
				const isFromExtension = searchParams.get('from') === 'extension'

				if (isFromExtension && window.opener) {
					window.opener.postMessage(
						{ type: 'AUTH_SUCCESS', payload: { token: data.token } },
						'*'
					)
					window.close()
				} else {
					navigate('/dashboard')
				}
			} else {
				const errorData = await response.json()
				setErrorMessage(errorData.message)
			}
		} catch (error) {
			console.error('Error during login:', error)
			setErrorMessage('An error occurred during login.')
		}
	}

	useEffect(() => {
		const handlePostMessage = event => {
			const { origin } = event
			const isValidOrigin =
				origin === window.location.origin ||
				origin === devURI ||
				origin === productionURI

			if (!isValidOrigin) {
				console.error('Received message from an untrusted origin:', origin)
				return
			}

			const { error, message, token, email } = event.data

			if (error) {
				setErrorMessage(message)
			} else if (token && email) {
				localStorage.setItem('jwtToken', token)
				localStorage.setItem('email', email)
				navigate('/dashboard')
			}
		}

		window.addEventListener('message', handlePostMessage)

		return () => {
			window.removeEventListener('message', handlePostMessage)
		}
	}, [navigate])

	useEffect(() => {
		if (errorMessage) {
			const timer = setTimeout(() => {
				setErrorMessage('')
			}, 5000)

			return () => clearTimeout(timer)
		}
	}, [errorMessage])

	const handleGoogleLogin = () => {
		const w = 500
		const h = 600
		const y = window.top.outerHeight / 2 + window.top.screenY - h / 2
		const x = window.top.outerWidth / 2 + window.top.screenX - w / 2

		const googleWindow = window.open(
			googleAuthUrl,
			'Google Sign-In',
			`toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=yes, resizable=yes, copyhistory=no, width=${w}, height=${h}, top=${y}, left=${x}`
		)

		window.addEventListener('message', event => {
			// if (event.origin !== window.location.origin) return

			console.log('event.data.token', event)

			if (event.data.token) {
				localStorage.setItem('jwtToken', event.data.token)
				localStorage.setItem('email', event.data.email)

				const searchParams = new URLSearchParams(location.search)
				const isFromExtension = searchParams.get('from') === 'extension'

				if (isFromExtension && window.opener) {
					console.log('from extension', event.data.token)

					window.opener.postMessage(
						{ type: 'AUTH_SUCCESS', payload: { token: event.data.token } },
						'*'
					)
					window.close()
				} else {
					navigate('/dashboard')
				}

				googleWindow.close()
			}
		})
	}

	return (
		<>
			<NavBar isNotLandingPage={true} />
			<div className="flex justify-center items-start pt-10 pb-10 min-h-[calc(100vh-64px)]">
				<div className="bg-white p-8 rounded shadow-md w-full max-w-md">
					<h2 className="text-2xl font-semibold mb-6 text-center">Login</h2>

					{errorMessage && (
						<div className="text-red-500 text-center mb-4">{errorMessage}</div>
					)}

					<button
						className="google-button w-full inline-flex items-center px-2.5 py-1.5 border border-gray-300 shadow-sm text-xs font-medium rounded-sm bg-white hover:bg-gray-50 focus:outline-none focus:ring-1 focus:ring-offset-2 focus:ring-indigo-500"
						type="button"
						onClick={handleGoogleLogin}
					>
						<img
							src={googleIcon}
							alt="Google"
							style={{ width: '20px', height: '20px' }}
						/>
						<p className="btn-text">Continue with Google</p>
					</button>

					<form onSubmit={handleSubmit} className="space-y-6">
						<div>
							<label
								htmlFor="email"
								className="block text-sm font-medium text-gray-700 mt-5"
							>
								Email
							</label>
							<input
								type="email"
								id="email"
								value={email}
								onChange={e => setEmail(e.target.value)}
								className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
							/>
						</div>
						<div>
							<label
								htmlFor="password"
								className="block text-sm font-medium text-gray-700"
							>
								Password
							</label>
							<input
								type="password"
								id="password"
								value={password}
								onChange={e => setPassword(e.target.value)}
								className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
							/>
						</div>
						<div>
							<button
								type="submit"
								className="w-full py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-blue-500 hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
							>
								Login
							</button>
						</div>
					</form>
					<div className="mt-6 text-sm text-center text-gray-500">
						Don't have an account?{' '}
						<a
							href={`/register${location.search}`}
							className="text-blue-500 hover:underline"
						>
							Register here
						</a>
					</div>

					<div className="mt-2 mb-4 text-xs text-center text-gray-500">
						By logging in, you agree to our{' '}
						<a href="/policy" className="text-blue-500 hover:underline">
							Privacy Policy
						</a>{' '}
						and{' '}
						<a href="/policy" className="text-blue-500 hover:underline">
							Terms and Conditions
						</a>
					</div>
				</div>
			</div>
		</>
	)
}

export default Login
