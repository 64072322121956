import React from 'react'
import {
	EnvelopeIcon,
	UserGroupIcon,
	ArrowPathIcon,
	PhoneIcon,
	StarIcon,
	CalculatorIcon,
	MapIcon,
} from '@heroicons/react/24/solid'

import './glowEffect.css'

const Hero = ({ chromeWebstoreUrl }) => {
	const features = [
		{ name: 'One-click email sending', icon: EnvelopeIcon },
		{ name: 'Detailed broker information', icon: UserGroupIcon },
		{ name: 'Click-to-Call functionality', icon: PhoneIcon },
		{ name: 'Auto-refresher for updates', icon: ArrowPathIcon },
		{ name: 'Profit calculator', icon: CalculatorIcon },
		{ name: 'Open Google Maps', icon: MapIcon },
	]

	const FeatureItem = ({ feature, className }) => (
		<div className={`flex items-center ${className}`}>
			<div className="bg-[#1d3f58] rounded-full p-4 mr-4">
				<feature.icon className="w-8 h-8 text-[#b3cde4]" />
			</div>
			<span className="text-base font-medium text-[#b3cde4]">
				{feature.name}
			</span>
		</div>
	)

	return (
		<div className="bg-gradient-to-b from-[#001b2e] to-[#003456] text-[#eef3f9] pt-8 pb-16 min-h-screen flex items-start">
			<div className="container mx-auto px-4 sm:px-6 lg:px-8">
				<div className="flex flex-col lg:flex-row items-start justify-between">
					<div className="lg:w-1/2 mb-8 lg:mb-0 pr-0 lg:pr-8 mt-8">
						<h1 className="text-5xl font-bold mb-6 text-white leading-tight">
							Revolutionize Your <br />
							DAT One Workflow
						</h1>
						<p className="text-2xl text-[#b3cde4] mb-12 leading-relaxed">
							Boost your productivity and increase <br /> your earnings today.
						</p>

						<a
							href={chromeWebstoreUrl}
							target="_blank"
							rel="noopener noreferrer"
							className="gradient-bg mb-12 bg-blue-600 text-white shadow-xl px-28 py-5 rounded-lg text-xl font-bold hover:bg-blue-500 transition duration-300 inline-block shadow-lg"
						>
							Get LoadLine.AI now
						</a>
						<div className="flex items-center">
							<div className="flex -space-x-4 mr-6">
								{[...Array(5)].map((_, i) => (
									<img
										key={i}
										className="w-10 h-10 rounded-full border-2 border-[#1d3f58]"
										src={`https://i.pravatar.cc/150?img=${i + 1}`}
										alt="User avatar"
									/>
								))}
							</div>
							<div>
								<div className="flex items-center mb-1">
									{[...Array(5)].map((_, index) => (
										<StarIcon key={index} className="w-5 h-5 text-yellow-300" />
									))}
								</div>
								<span className="text-base text-[#b3cde4]">
									283 dispatchers sleep better
								</span>
							</div>
						</div>
					</div>
					<div className="lg:w-1/2 mt-14 lg:mt-9">
						<div className="bg-[#1d3f58] bg-opacity-20 rounded-xl p-12 shadow-xl">
							<div className="grid grid-cols-1 md:grid-cols-2 gap-9">
								{features.map((feature, index) => (
									<FeatureItem key={index} feature={feature} />
								))}
							</div>
							<div className="text-left mt-10">
								<p className="text-lg text-[#b3cde4] font-medium">
									+ Extra features like screenshot, factoring score, and more.
								</p>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}

export default Hero
